export default {
  namespaced: true,
  state () {
    return {
      adForm: null,
    }
  },
  mutations: {
    setAdForm(state, payload) {
      state.adForm = payload;
    },
  },
  getters: {
    getAdForm: (state) => state.adForm,
  }
}