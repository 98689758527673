<template>
  <div class="d-lg-flex half">
    <div class="contents order-1 order-md-2">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-7">
            <div class="card" style="border-radius: 0.5rem">
              <div class="card-body shadow-sm">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg order-2 order-md-1" style="background-image: url('/images/auth-bg.jpg');">
      <img src="/assets/images/logo.png"
           alt="logo"
           style="height: 87px; padding: 12px; top: 25px; left: 25px; position: relative; background: rgba(0, 0, 0, .5); border-radius: 10px;">
    </div>
  </div>
</template>

<style scoped>
@import "../../assets/css/auth.css";
</style>