import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";
import connect from "@/middlewares/connect";
import editor from "@/middlewares/editor";
import hasPlan from "@/middlewares/hasPlan";
import { advertiseRoutes } from "@/routes/advertiseRoutes";
import { reportRoutes } from "@/routes/reportRoutes";
import { createCampaignRoutes } from "@/routes/createCampaignRoutes";
import { keywordRoutes } from "@/routes/keywordRoutes";
import { teamRoutes } from "@/routes/teamRoutes";
import { businessSettingsRoutes } from "@/routes/businessSettingsRoutes";
import { keywordRankingRoutes } from "@/routes/keywordRankingRoutes";
import { aweberRoutes } from "@/routes/aweberRoutes";

let routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    name: 'dashboard',
    meta: {
      middleware: [
        connect, auth
      ]
    }
  },
  // {
  //   path: '/tweet-track',
  //   component: () => import(/* webpackChunkName: "shorten_link" */ '../views/create_campaign/Influencer'),
  //   name: 'shorten_link',
  //   meta: {
  //     middleware: [
  //       auth
  //     ]
  //   }
  // },
  {
    path: '/reset',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Reset'),
    name: 'reset'
  },
  {
    path: '/invitation',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Invitation.vue'),
    name: 'invitation',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Settings'),
    name: 'settings',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Profile.vue'),
    name: 'profile',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/change-password',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/ChangePassword.vue'),
    name: 'change_password',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/contact-us',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/ContactUs.vue'),
    name: 'contact_us',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/mapping',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Mapping'),
    name: 'mapping',
    meta: {
      middleware: [
        connect, editor, hasPlan, auth
      ]
    }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/signup',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Signup.vue'),
    name: 'signup',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/forget-password',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/ForgetPassword.vue'),
    name: 'forget_password',
    meta: {
      middleware: [
        guest
      ]
    }
  },
]

routes.push(...advertiseRoutes);
routes.push(...reportRoutes);
routes.push(...createCampaignRoutes);
routes.push(...keywordRoutes);
routes.push(...teamRoutes);
routes.push(...businessSettingsRoutes);
routes.push(...keywordRankingRoutes);
routes.push(...aweberRoutes);

export default routes;
