import { createStore } from 'vuex'
import authModule from './modules/auth'
import adAccounts from './modules/adAccounts'
import settings from './modules/settings'
import planModules from './modules/planModules'
import fbAdsModule from './modules/fbAdsModule'
import googleWizardModule from './modules/googleWizardModule'
import subscriptionModule from './modules/subscriptionModule'
import dashboardModule from './modules/dashboardModule'
import googleKeywordModule from './modules/googleKeywordModule'
import twitterWizardModule from './modules/twitterWizardModule'
import axios from "@/util/axios";

const store = createStore({
  state () {
    return {
      labels: [],
      versionLogs: [],
      loadingApp: false,
      countries: [],
      currentAmazonProfileId: ''
    }
  },
  mutations: {
    setLabels(state, payload) {
      state.labels = payload
    },
    setVersionLogs(state, payload) {
      state.versionLogs = payload
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setCurrentAmazonProfileId(state, payload) {
      state.currentAmazonProfileId = payload;
    },
    setLoadingApp(state, payload) {
      state.loadingApp = payload;
    }
  },
  actions: {
    loadLabels({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/labels').then((response) => {
          commit('setLabels', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      });
    },
    loadVersionLogs({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/versions').then((response) => {
          commit('setVersionLogs', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      });
    },
    loadCountries({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/countries').then((response) => {
          commit('setCountries', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error)
        });
      });
    },
  },
  getters: {
    getLabels: (state) => state.labels,
    getVersionLogs: (state) => state.versionLogs,
    getCountries: (state) => state.countries,
    getLoadingApp: (state) => state.loadingApp,
    getCurrentAmazonProfileId: (state) => state.currentAmazonProfileId,
  },

  modules: {
    auth: authModule,
    adAccounts: adAccounts,
    settings: settings,
    plan: planModules,
    fbAds: fbAdsModule,
    googleWizard: googleWizardModule,
    subscriptionModule: subscriptionModule,
    dashboardModule: dashboardModule,
    googleKeywordModule,
    twitterWizardModule,
  }
})

export default store