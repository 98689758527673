<template>
  <div class="modal fade"
       id="modal-app-loading"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       data-backdrop="static"
       data-keyboard="false"
       tabindex="-1"
       aria-labelledby="staticBackdropLabel"
       aria-hidden="true"
       role="dialog">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p>Please wait...</p>
        </div><!--end modal-body-->
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null,
    }
  },
  computed: {
    ...mapGetters({
      loadingApp: 'getLoadingApp'
    }),
  },
  watch: {
    loadingApp(val) {
      if (val) {
        this.show();
      } else {
        this.hide();
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(
        document.getElementById('modal-app-loading'), {
          backdrop: 'static'
        });

    if (this.loadingApp) {
      this.show();
    }
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>