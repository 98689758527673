import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      currentPlan: null,
      isLoadingCurrentPlan: false,
      revoked: false,
      popupShowing: false,
      defaultPaymentMethod: null
    }
  },
  mutations: {
    setCurrentPlan(state, payload) {
      state.currentPlan = payload;
    },
    setDefaultPaymentMethod(state, payload) {
      state.defaultPaymentMethod = payload;
    },
    setRevoked(state, payload) {
      state.revoked = payload;
    },
    setPopupShowing(state, payload) {
      state.popupShowing = payload;
    },
    setIsLoadingCurrentPlan(state, payload) {
      state.isLoadingCurrentPlan = payload;
    }
  },
  actions: {
    getCurrentPlan({commit}) {
      return new Promise((resolve, reject) => {
        commit('setIsLoadingCurrentPlan', true);

        axios.get('/current-plan').then((response) => {
          // let data = response.data.data ? response.data.data : response.data

          if (response.data.data)
            commit('setCurrentPlan', response.data.data);
          else {
            if (response.data.message === 'revoked') {
              commit('setRevoked', true);
            }
          }

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        }).finally(() => {
          commit('setIsLoadingCurrentPlan', false);
        });
      });
    },
    getDefaultPaymentMethod({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/default-payment').then((response) => {
          commit('setDefaultPaymentMethod', response.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
  },
  getters: {
    getCurrentPlan: (state) => state.currentPlan,
    getIsLoadingCurrentPlan: (state) => state.isLoadingCurrentPlan,
    getRevoked: (state) => state.revoked,
    getPopupShowing: (state) => state.popupShowing,
    getDefaultPaymentMethod: (state) => state.defaultPaymentMethod,
  }
}