import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      license: null,
    }
  },
  mutations: {
    setLicense(state, payload) {
      state.license = payload;
    },
  },
  actions: {
    checkLicense({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/apply-license', {
          license: payload
        }).then((response) => {
          commit('setLicense', payload);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
  },
  getters: {
    getLicense: (state) => state.license,
  }
}