<template>
  <div class="sidebar-wrapper sidebar-theme fixed-top">
    <div id="dismiss" class="d-lg-none"><i class="flaticon-cancel-12"></i></div>
    <nav id="sidebar">
      <ul class="navbar-nav theme-brand flex-row  d-none d-lg-flex">
        <li class="nav-item d-flex">
          <router-link :to="{name: 'dashboard'}" class="p-4">
            <img :src="baseUrl + 'assets/images/logo.png'" class="img-fluid brand-logo" alt="logo"  />
          </router-link>
        </li>
      </ul>

      <ul class="list-unstyled menu-categories" id="accordionExample">

        <li class="menu">
          <router-link :to="{name: 'dashboard'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div>
              <i class="flaticon-computer-6 ml-3"></i>
              <span>Dashboard</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="account && account.role !== 3">
          <a href="#ecommerce" data-toggle="collapse" aria-expanded="false" class="nav-item dropdown-toggle">
            <div class="">
              <i class="flaticon-layers"></i>
              <span>Create Campaign</span>
            </div>
            <div>
              <i class="flaticon-right-arrow"></i>
            </div>
          </a>
          <ul class="collapse submenu list-unstyled" id="ecommerce" data-parent="#accordionExample">
            <li :class="{'active': ['create_campaign_twitter'].includes($route.name)}">
              <router-link v-if="currentPlan && currentPlan.is_attribution_x" :to="{name: 'create_campaign_twitter'}">Create X Campaign</router-link>
              <a href="#"
                 v-else
                 class="text-muted">
                🚫 Create X Campaign
              </a>
            </li>
          </ul>
        </li>

        <li class="menu">
          <router-link :to="{name: 'tweet_track'}" class="nav-item">
            <div class="">
              <i class="flaticon-link"></i>
              <span>TweetTrack</span>
            </div>
          </router-link>
        </li>

        <li class="menu" v-if="account && (account.role === 1 || account.role === 2)">
          <router-link :to="{name: 'settings'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-settings-1"></i>
              <span>Settings
                <i style="color: red!important; font-size: 12px"
                   v-if="settings && settings.google_user_id && !settings.google_ads_scope"
                   class="fas fa-circle"></i>
              </span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="account && (account.role === 1 || account.role === 2)">
          <router-link :to="{name: 'team'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-user-1"></i>
              <span>Team</span>
            </div>
          </router-link>
        </li>
        <li class="menu">
          <router-link :to="{name: 'contact_us'}" data-toggle="collapse" aria-expanded="false" class="nav-item">
            <div class="">
              <i class="flaticon-email-1"></i>
              <span>Contact Us</span>
            </div>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      features: 'auth/getFeatures',
      settings: 'settings/getSettings',
      account: 'auth/getAccount',
      currentPlan: 'plan/getCurrentPlan',
      popupShowing: 'plan/getPopupShowing'
    })
  },
  mounted() {
    window.$(".nav-item").on("click", function() {
      window.$(".dropdown-toggle").not(this).attr("aria-expanded", "false")
      window.$(".dropdown-toggle").not(this).addClass("collapsed")
      window.$(".list-unstyled").not(window.$(this).next("ul")).removeClass("show")
    })

  }
}
</script>