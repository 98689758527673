import auth from "../middlewares/auth";
export const advertiseRoutes = [
  {
    path: '/advertise/facebook',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/advertise/Facebook'),
    name: 'advertise_facebook',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/advertise/amazon',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/advertise/Amazon'),
    name: 'advertise_amazon',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/advertise/google',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/advertise/Google'),
    name: 'advertise_google',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/advertise/facebook/:id/:act',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/advertise/FacebookAdsDetails'),
    name: 'advertise_facebook_details',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];