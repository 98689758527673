import auth from "../middlewares/auth";
import connect from "@/middlewares/connect";
import editor from "@/middlewares/editor";
import hasPlan from "@/middlewares/hasPlan";
export const createCampaignRoutes = [
  // {
  //   path: '/google-wizard',
  //   component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/google_wizard/Template'),
  //   name: 'google_wizard',
  //   meta: {
  //     middleware: [
  //       connect, editor, hasPlan, auth
  //     ]
  //   }
  // },
  // {
  //   path: '/create-campaign/facebook',
  //   component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/Facebook'),
  //   name: 'create_campaign_facebook',
  //   meta: {
  //     middleware: [
  //       connect, editor, hasPlan, auth
  //     ]
  //   }
  // },
  {
    path: '/create-campaign/twitter',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/Twitter.vue'),
    name: 'create_campaign_twitter',
    meta: {
      middleware: [
        connect, editor, hasPlan, auth
      ]
    }
  },
  {
    path: '/tweet-track',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/Influencer'),
    name: 'tweet_track',
    meta: {
      middleware: [
        connect, editor, hasPlan, auth
      ]
    }
  },
  // {
  //   path: '/create-campaign/send-mail/:id',
  //   component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/InfluencerAweber.vue'),
  //   name: 'influencer_send_mail',
  //   meta: {
  //     middleware: [
  //       connect, editor, hasPlan, auth
  //     ]
  //   }
  // },
  {
    path: '/tweet-track/:id',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/create_campaign/InfluencerDetails'),
    name: 'tweet_track_details',
    meta: {
      middleware: [
        connect, editor, hasPlan, auth
      ]
    }
  },
  /*{
    path: '/create-campaign/google',
    component: () => import(/!* webpackChunkName: "jobs" *!/ '../views/create_campaign/Google'),
    name: 'create_campaign_google',
    meta: {
      middleware: [
        auth, connect
      ]
    }
  },*/
];