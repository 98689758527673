import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      settings: [],
      dashboardColumns: [
        {
          name: 'Spend',
          show: true
        },
        {
          name: 'Impressions',
          show: true
        },
        {
          name: 'Clicks',
          show: true
        },
        {
          name: 'CPC',
          show: true
        },
        {
          name: 'CTR',
          show: true
        },
        {
          name: 'Add To Cart',
          show: true
        },
        {
          name: 'Conversions',
          show: true
        },
        {
          name: 'Revenue',
          show: true
        },
        {
          name: 'ACoS',
          show: true
        },
        {
          name: 'Brand Referral Bonus',
          show: true
        },
        {
          name: 'Status',
          show: true
        }
      ],
      keywordColumns: [
        {
          name: 'Match Type',
          show: true,
        },
        {
          name: 'Status',
          show: true,
        },
        {
          name: 'Impressions',
          show: true,
        },
        {
          name: 'Clicks',
          show: true,
        },
        {
          name: 'Cost',
          show: true,
        },
        {
          name: 'Average CPC',
          show: true,
        },
        {
          name: 'Max CPC',
          show: true,
        },
        {
          name: 'Add To Carts',
          show: true,
        },
        {
          name: 'Conversions',
          show: true,
        },
        {
          name: 'Revenue',
          show: true,
        },
        {
          name: 'AMZ Organic Rank',
          show: true,
        },
        {
          name: 'KW Tracking',
          show: true,
        },
        {
          name: 'Rank History',
          show: true,
        },
        {
          name: 'ACoS',
          show: true,
        }
      ],
      loadingApp: true,
    }
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload;
    },
    setLoadingApp(state, payload) {
      state.loadingApp = payload;
    },
    setDashboardColumns(state, payload) {
      state.dashboardColumns = payload;
    },
    setKeywordColumns(state, payload) {
      state.keywordColumns = payload;
    }
  },
  actions: {
    loadSettings({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/settings').then((response) => {
          commit('setSettings', response.data.data);
          commit('setDashboardColumns', response.data.data.dashboard_columns);
          commit('setKeywordColumns', response.data.data.kw_columns);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    saveSettings({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/settings', payload).then((response) => {
          commit('setSettings', response.data.data);

          resolve(response);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
  },
  getters: {
    getSettings: (state) => state.settings,
    getGoogleFetchDone: (state) => state.settings && state.settings.is_google_fetch_done,
    getLoadingApp: (state) => state.loadingApp,
    getDashboardColumns: (state) => state.dashboardColumns,
    getKeywordColumns: (state) => state.keywordColumns,
  }
}