<template>
  <div class="modal fade" id="modal-change-log" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-add-keywordLabel">Change Log</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-for="(item, i) in versionLogs" :key="'version_'+i">
            <h6 class="text-black font-weight-bold">
              v{{ item.version }}

              <span class="text-muted fs-10 float-right font-weight-normal">{{ item.created_at }}</span>
            </h6>
            <ul class="text-black">
              <li>{{ item.task }}</li>
            </ul>

            <hr v-if="i <= versionLogs.length-2">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      modal: null
    }
  },
  computed: {
    ...mapGetters({
      versionLogs: 'getVersionLogs'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-change-log'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>