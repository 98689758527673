<template>
  <div class="modal fade" id="modal-revoked-license"
       tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p>{{ message }} <br><br>
            To continue using our platform without an agency, you can choose a plan and sign up <a href="#" @click.prevent="showWelcomeModal">(HERE)</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      message: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-revoked-license'), {
      backdrop: 'static'
    })
  },
  methods: {
    show(message) {
      this.message = message;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    showWelcomeModal() {
      this.$emit('showWelcomeModal');
      this.hide();
    }
  }
}
</script>