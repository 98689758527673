<template>
  <div class="row">
    <div class="col-3"></div>
    <div class="col-6">
      <input class="form-control"
             ref="licenseInput"
             :disabled="license"
             placeholder="License">
    </div>
  </div>

  <div class="row mt-3" v-if="!license">
    <div class="col-md-12 text-center">
      <button class="btn btn-sm btn-secondary mr-2"
              @click="$emit('goNext')"
              :disabled="loading">Skip</button>
      <button class="btn btn-sm btn-success"
              :disabled="loading"
              @click="applyLicense">Apply</button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      license: "subscriptionModule/getLicense"
    })
  },
  methods: {
    applyLicense() {
      this.loading = true;

      let license = this.$refs.licenseInput.value;

      this.$store.dispatch('subscriptionModule/checkLicense', license)
        .then((res) => {
          this.$store.dispatch('plan/getCurrentPlan')
          this.$store.dispatch('auth/loadFeatures')
          this.$emit('licenseApplied', res.data);
        })
        .catch((err) => {
          this.showFailMsg(err.message);
        })
        .finally(() => this.loading = false);
    }
  }
}
</script>