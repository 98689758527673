import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      amazonProduct: null,
      wizardLoading: false
    }
  },
  mutations: {
    setAmazonProduct(state, payload) {
      state.amazonProduct = payload;
    },
    setWizardLoading(state, payload) {
      state.wizardLoading = payload;
    },
  },
  actions: {
    getAmazonProduct({commit}, payload) {
      return new Promise((resolve, reject) => {
        commit('setAmazonProduct', null);

        if (payload) {
          commit('setWizardLoading', true);

          axios.get('/amazon/product/' + payload.asin + '/' + payload.countryCode).then((response) => {
            let product = response.data.data;
            commit('setAmazonProduct', product);
            resolve(product);
          }).catch((error) => {
            reject(error.response)
          }).finally(() => {
            commit('setWizardLoading', false);
          });
        } else {
          resolve(true);
        }
      });
    },
  },
  getters: {
    getAmazonProduct: (state) => state.amazonProduct,
    getWizardLoading: (state) => state.wizardLoading,
  }
}