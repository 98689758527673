<template>
  <div class="modal fade" id="upgrade-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Payment Information</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <input class="form-control form-control-sm"
                     :disabled="loading"
                     v-model="card.card_no"
                     placeholder="Card number"
                     type="text">
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <input class="form-control form-control-sm"
                     :disabled="loading"
                     v-model="card.expire"
                     placeholder="Expire (MM-YYYY)"
                     type="text">
            </div>
            <div class="col-6">
              <input class="form-control form-control-sm"
                     :disabled="loading"
                     v-model="card.cvc"
                     placeholder="CVC"
                     type="text">
            </div>
          </div>

          <v-errors :errors="errorFor('name')" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary"
                  :disabled="loading"
                  @click="upgrade">Upgrade</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      card: {
        card_no: '',
        cvc: '',
        expire: '',
      }
    }
  },
  computed: {
    expireMonth() {
      if (this.card.expire.length > 1) {
        return this.card.expire.substring(0, 2);
      } else {
        return '';
      }
    },
    expireYear() {
      if (this.card.expire.length > 6) {
        return this.card.expire.substring(3, 7);
      } else {
        return '';
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('upgrade-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    stripeResponseHandler(status, response) {
      this.loading = false;

      if (response.error) {
        this.showFailMsg(response.error.message)
      } else {
        let token = response['id'];

        this.loading = true;

        this.axios.post('/upgrade-influencer', {
            number: this.card.card_no,
            cvc: this.card.cvc,
            exp_month: this.expireMonth,
            exp_year: this.expireYear,
            payment_token: token,
          })
          .then(() => {
            location.reload();
          })
          .finally(() => this.loading = false);
      }
    },
    upgrade() {
      let self = this;
      this.loading = true;

      window.Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      window.Stripe.createToken({
        number: self.card.card_no,
        cvc: self.card.cvc,
        exp_month: self.expireMonth,
        exp_year: self.expireYear
      }, self.stripeResponseHandler);
    }
  }
}
</script>