export const interestsCategories = [
  {
    "name": "Automotive"
  },
  {
    "name": "Beauty"
  },
  {
    "name": "Books and literature"
  },
  {
    "name": "Business"
  },
  {
    "name": "Careers"
  },
  {
    "name": "Education"
  },
  {
    "name": "Events"
  },
  {
    "name": "Family and parenting"
  },
  {
    "name": "Food and drink"
  },
  {
    "name": "Gaming"
  },
  {
    "name": "Health"
  },
  {
    "name": "Hobbies and interests"
  },
  {
    "name": "Home and garden"
  },
  {
    "name": "Law, government, and politics"
  },
  {
    "name": "Life stages"
  },
  {
    "name": "Movies and television"
  },
  {
    "name": "Music and radio"
  },
  {
    "name": "Personal finance"
  },
  {
    "name": "Pets"
  },
  {
    "name": "Science"
  },
  {
    "name": "Society"
  },
  {
    "name": "Sports"
  },
  {
    "name": "Style and fashion"
  },
  {
    "name": "Technology and computing"
  },
  {
    "name": "Travel"
  }
];