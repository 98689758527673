<template>
  <div>
    <div class="selected-package-wrapper">
      <h1 class="selected-package">
        {{ selectedPackage.plan }}: <span :class="{'line-through': promoObj}">${{ selectedPackage.price }}</span> <span v-if="promoObj"> {{ promoObj.name }}</span>
      </h1>
    </div>
    <div class="card-input-whole-wrapper">
      <div class="card-input-wrapper">
        <input class="card-number"
               type="text"
               :disabled="loading"
               v-model="card.card_no"
               placeholder="Card number"/>

        <input class="expire"
               type="text"
               :disabled="loading"
               v-model="card.expire"
               placeholder="Expire (MM-YYYY)" />

        <input class="cvc"
               type="text"
               :disabled="loading"
               v-model="card.cvc"
               placeholder="CVC" />

        <input class="cvc"
               type="text"
               :disabled="loading || promoObj"
               v-model="promo"
               placeholder="Promo Code" />

        <div class="button-wrapper">
          <div class="cp-spinner cp-round mt-3"
               v-if="loading"></div>

          <button @click="applyPromo"
                  style="color: white !important;"
                  class="btn-primary text-white"
                  v-if="!loading && promo && !promoObj">
            Apply Promo Code
          </button>


          <button @click="proceed"
                  v-if="!loading">
            Proceed
            <span class="right-arrow">
                <i class="fa-solid fa-arrow-right"></i>
              </span>
          </button>
          <button v-if="!loading"
                  @click="changePlan">Change plan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedPackage: Object
  },
  data() {
    return {
      promoObj: null,
      promo: '',
      card: {
        card_no: '',
        expire: '',
        cvc: '',
      }
    }
  },
  computed: {
    expireMonth() {
      if (this.card.expire.length > 1) {
        return this.card.expire.substring(0, 2);
      } else {
        return '';
      }
    },
    expireYear() {
      if (this.card.expire.length > 6) {
        return this.card.expire.substring(3, 7);
      } else {
        return '';
      }
    }
  },
  methods: {
    stripeResponseHandler(status, response) {
      this.loading = false;

      if (response.error) {
        this.showFailMsg(response.error.message)
      } else {
        let token = response['id'];
        this.loading = true;

        this.axios.post('/subscribe', {
            price: this.selectedPackage.price_id,
            number: this.card.card_no,
            cvc: this.card.cvc,
            exp_month: this.expireMonth,
            exp_year: this.expireYear,
            payment_token: token,
            promo_id: this.promoObj ? this.promoObj.id : null
          })
          .then(() => {
            this.$store.dispatch('plan/getCurrentPlan');
            this.$store.dispatch('plan/getDefaultPaymentMethod');
            this.$emit('goNext');
          })
          .finally(() => this.loading = false)
      }
    },
    applyPromo() {
      this.loading = true;

      this.axios.get('/check-promo', {
          params: {
            code: this.promo,
            product_id: this.selectedPackage.product_id
          }
        })
        .then(res => {
          this.promoObj = res.data.data;
        })
        .catch(err => {
          this.showFailMsg(err.response.data.data.message);
        })
        .finally(() => this.loading = false)
    },
    proceed() {
      let self = this;
      this.loading = true;

      window.Stripe.setPublishableKey(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      window.Stripe.createToken({
        number: self.card.card_no,
        cvc: self.card.cvc,
        exp_month: self.expireMonth,
        exp_year: self.expireYear
      }, self.stripeResponseHandler);
    },
    changePlan() {
      this.$emit('changePlan');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
