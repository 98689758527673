import auth from "../middlewares/auth";
export const aweberRoutes = [
  {
    path: '/aweber',
    component: () => import(/* webpackChunkName: "aweber" */ '../views/aweber/Dashboard.vue'),
    name: 'aweber_dashboard',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];