<template>
  <button type="button"
          v-if="settings && settings.twitter_user_id"
          @click.prevent="socialLogout"
          class="social-btn amazon logout">
    <div class="social-btn-icon">
      <i class="fab fa-x-twitter me-2"></i>
    </div>
    Logout {{ settings.twitter_screen_name }}
  </button>

  <a href="#"
     v-else
     @click.prevent="getRedirectUrl"
     class="social-btn amazon connect">
    <div class="social-btn-icon">
      <i class="fab fa-x-twitter me-2"></i>
    </div>
    Connect To X
  </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
    }),
  },
  created() {
    if ("oauth_token" in this.$route.query && "oauth_verifier" in this.$route.query) {
      this.axios.post('/settings/twitterAccessToken', {
        oauth_token: this.$route.query.oauth_token,
        oauth_verifier: this.$route.query.oauth_verifier,
      });
    }
  },
  methods: {
    getRedirectUrl() {
      this.loading = true;
      this.axios.get('/settings/twitterAuthUri')
          .then((response) => {
            window.location.href = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    socialLogout() {
      this.$emit('socialLogout', 'twitter');
    }
  }
}
</script>