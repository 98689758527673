<template>
  <div v-if="!selectedPackage">
    <div>
      <p class="text" style="width: 85%">
        Every plan comes with 14 days free trial (your card will not be charged until then). Cancel any time.
      </p>
      <!--      <p class="text">Choose your plan:</p>-->
    </div>

    <section class="pricing-section bg-7">
      <div class="pricing pricing--norbu">
        <div class="pricing__item"
             v-for="card in packages"
             :key="card.id">
          <div class="pricing__price"><span class="pricing__currency">$</span>{{ card.price }}<span class="pricing__period"> /&nbsp; {{ card.period }}</span></div>
          <!--          <h4 class="pricing__original_price" v-html="card.original_price"></h4>-->
          <ul class="pricing__feature-list text-center">
            <li class="pricing__feature">14 days free trial included</li>
          </ul>
          <button class="pricing__action mx-auto mb-2"
                  :disabled="loading"
                  @click="createPaymentLink(card)">Start Free Trial</button>
          <button class="pricing__action mx-auto font-weight-bold btn-skip-trial"
                  :disabled="loading"
                  @click="createPaymentLink(card, true)">Skip free trial and get 5% off in perpetuity</button>
        </div>
      </div>
    </section>
  </div>
  <div v-else>
    <Payment @changePlan="selectedPackage = null"
             @goNext="$emit('goNext')"
             :selectedPackage="selectedPackage" />
  </div>
</template>

<script>
import Payment from "./pay/Payment.vue";
export default {
  components: {Payment},
  data() {
    return {
      selectedPackage: null,
      monthly: true,
      packages: [
        {
          id: 1,
          name: 'Monthly',
          period: 'Monthly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_AMAZON_X_MONTHLY_PRICE_ID,
          price: 99
        },
        {
          id: 2,
          name: 'Yearly',
          period: 'Yearly',
          monthly: true,
          price_id: process.env.VUE_APP_STRIPE_AMAZON_X_YEARLY_PRICE_ID,
          price: 790
        },
      ]
    }
  },
  methods: {
    createPaymentLink(pack, skipTrial = false) {
      this.loading = true;

      this.axios.post('/payment-link', {
        price_id: pack.price_id,
        platform: 'x',
        skipTrial
      }).then((res) => {
        window.location.href = res.data.data.url;
      }).finally(() => this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/style.scss";
@import "@/assets/scss/pricing.scss";
</style>
