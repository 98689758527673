export default function owner ({ next, store }) {
  let account = store.state.auth.account;

  if (account && account.role !== 1 && account.role !== 2) {
    return next({
      name: 'dashboard'
    })
  }

  return next()
}