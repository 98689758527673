<template>
  <div class="modal fade"
       id="google-popup-modal"
       data-bs-keyboard="false" aria-labelledby="staticBackdropLabel"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body p-3">
          <img class="w-100" src="/images/instruction.gif">
        </div>
        <div class="modal-footer p-3">
          <a class="google-btn step-one-btn-width"
             type="button"
             v-if="googleAuthUrl"
             :href="googleAuthUrl">
            <div class="google-icon-wrapper">
              <img
                class="google-icon"
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
              />
            </div>
            <p class="btn-text">Sign in with Google</p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--end modal-->
</template>

<script>

export default {
  data() {
    return {
      modal: null,
      googleAuthUrl: null,
    };
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(
      document.getElementById("google-popup-modal")
    );
  },
  created() {
    this.loadGoogleAuthUrl();
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadGoogleAuthUrl() {
      this.axios.get('/settings/googleAuthUri')
        .then(response => {
          this.googleAuthUrl = response.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>
