export default {
  namespaced: true,
  state () {
    return {
      table: {
        sortColumn: 'impressions',
        sortOrder: 'desc',
      }
    }
  },
  mutations: {
    setTable(state, payload) {
      state.table = payload;
    }
  }
}