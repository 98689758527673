import auth from "../middlewares/auth";
export const keywordRoutes = [
  {
    path: '/keywords/google/:id/:profileId',
    component: () => import(/* webpackChunkName: "keywords" */ '../views/keywords/GoogleKeywords'),
    name: 'keywords_google',
    meta: {
      middleware: [
        auth
      ]
    }
  },
];