import auth from "../middlewares/auth";
export const businessSettingsRoutes = [
  {
    path: '/business_settings',
    component: () => import(/* webpackChunkName: "business_settings" */ '../views/business_settings/Index.vue'),
    name: 'business_settings',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/business_settings/edit/:id',
    component: () => import(/* webpackChunkName: "business_settings" */ '../views/business_settings/Edit.vue'),
    name: 'business_settings_edit',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];