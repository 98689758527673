export default {
  namespaced: true,
  state () {
    return {
      forceLoad: false,
      queryParams: {
        onlyMapped: 1,
        profileId: '',
        startDate: window.moment().subtract(3, "years").startOf("month").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      },
      displayBy: 'name',
      campaigns: [],
    }
  },
  mutations: {
    setQueryParams(state, payload) {
      state.queryParams = payload;
    },
    setCampaigns(state, payload) {
      state.campaigns = payload;
    },
    setDisplayBy(state, payload) {
      state.displayBy = payload;
    },
    setForceLoad(state, payload) {
      state.forceLoad = payload;
    },
  },
  actions: {

  },
  getters: {
    getQueryParams: (state) => state.queryParams,
    getCampaigns: (state) => state.campaigns,
    getForceLoad: (state) => state.forceLoad,
  }
}