export default {
  methods: {
    fbLogin() {
      if (this.currentPlan && this.currentPlan.is_influencer) {
        this.showInfluencerLicenseMessage();
        return;
      }

      let self = this;
      window.FB.login(function(response) {
        if (response.authResponse) {
          self.fbLoginResponse(response.authResponse)
        }
      }, {
        scope: 'email,ads_read,ads_management,pages_show_list,pages_read_engagement'
      });
    },
    fbLoginResponse(response) {
      this.saveSettings({
        fb_user_id: response.userID,
        fb_access_token: response.accessToken,
      })
    },
    saveSettings(payload) {
      this.axios.post('/settings', payload)
        .then((response) => {
          this.$store.commit('settings/setSettings', response.data.data);

          if (this.settings.fb_user_id)
            this.$store.dispatch('adAccounts/loadFbAccounts');

          if (this.settings.amazon_user_id)
            this.$store.dispatch('adAccounts/loadAmazonAccounts');

          this.checkStatus();
        })
        .catch(() => {
          this.showFailMsg("Something went wrong!");
        })
    },
  },
}