export default function hasPlan ({ next, store }) {
  let currentPlan = store.state.plan.currentPlan;
  let account = store.state.auth.account;

  if ((!currentPlan || currentPlan.past_due) && account.role === 1) {
    return next({
      name: 'settings'
    })
  }

  return next()
}