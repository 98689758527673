<template>
  <div>
    <div>
      <p class="text">
        You're a few clicks away from taking your advertising to the next level!
        Let's start by connecting your accounts:
      </p>
      <p class="text">
        Connect your Amazon account. This will allow us access to your
        advertising data and allow us to create attribution links for you.
      </p>
    </div>

    <div class="row mt-3">
      <div class="col-3"></div>
      <div class="col-6">
        <a class="social-btn amazon-btn"
           type="button"
           v-if="settings && settings.amazon_user_id">
          <div class="social-btn-icon">
            <i class="fab fa-amazon me-2"></i>
          </div>
          {{ settings.amazon_name }}
        </a>
        <a class="social-btn amazon-btn"
           v-else
           :href="amazonAuthUrl">
          <div class="social-btn-icon">
            <i class="fab fa-amazon me-2"></i>
          </div>
          Connect To Amazon
        </a>
      </div>
    </div>

    <div class="free-trail-button-wrapper mt-3">
      <button
        :disabled="!canGoNext"
        @click="goNext"
        class="free-trail-button step-one-btn-width relative">
        Let's start your free trial
        <span class="free-trail-right-arrow absolute">
          <i class="fa-solid fa-arrow-right"></i>
        </span>
      </button>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import settingsMixins from "@/mixins/settingsMixins";

export default {
  mixins: [settingsMixins],
  data() {
    return {
      amazonAuthUrl: 'https://www.amazon.com/ap/oa?client_id='+process.env.VUE_APP_AMAZON_CLIENT_ID+'&scope=profile%20advertising::test:create_account%20advertising::campaign_management&response_type=code&redirect_uri=' + process.env.VUE_APP_AMAZON_REDIRECT_URI + '&state=amazon',
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
    }),
    canGoNext() {
      return this.settings && this.settings.amazon_user_id;
    }
  },
  methods: {
    goNext() {
      if (this.canGoNext) {
        this.$emit('goNext');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/style.scss";
</style>
