<template>
  <div class="modal fade"
       id="welcome-modal"
       data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content popup-background border-0">
        <SubscribePopup ref="modalContent" @hide="hide" />
      </div>
    </div>
  </div>
  <!--end modal-->
</template>

<script>
import SubscribePopup from "@/components/subs-popup/SubscribePopup.vue";

export default {
  components: { SubscribePopup },
  data() {
    return {
      modal: null,
    };
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(
      document.getElementById("welcome-modal"), {
        backdrop: 'static'
      }
    );
  },
  methods: {
    show(step =1) {
      this.$refs.modalContent.changeStep(step);
      this.modal.show();

      this.$store.commit('plan/setPopupShowing', true);
    },
    hide() {
      this.modal.hide();
      this.$store.commit('plan/setPopupShowing', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../subs-popup/style/style.scss";
</style>
