<template>
  <p class="text">
    Connect your Google/Facebook account. This will allow us access to your
    ads data allow you to create campaigns with attribution links through our
    system
  </p>

  <div class="row mt-5">
    <div class="col-3"></div>
    <div class="col-6">
      <twitter-button />
    </div>
  </div>

  <div class="free-trail-button-wrapper mt-3">
    <button @click="finish"
            class="free-trail-button step-one-btn-width relative">
      <span>Finish</span>
    </button>
  </div>

  <teleport to="body">
    <google-popup ref="googlePopup" />
  </teleport>
</template>

<script>
import settingsMixins from "@/mixins/settingsMixins";
import GooglePopup from "./GooglePopup.vue";
import TwitterButton from "@/components/settings/TwitterButton.vue";
import {mapGetters} from "vuex";

export default {
  components: {GooglePopup, TwitterButton},
  mixins: [settingsMixins],
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
    }),
  },
  methods: {
    finish() {
      this.$emit('hide');
      this.axios.post('/settings', {
        is_setup_done: 1
      })
    },
    socialLogout(service) {
      this.axios.post('/settings/logout/'+service)
        .then(response => {
          this.$store.commit('settings/setSettings', response.data.data);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/style.scss";
</style>
