<script>
export default {
  props: {
    dispayBtnNo: Number,
  },
  setup() {
    return {};
  },
};
</script>

<template>
  <div class="stepper-whole-wrapper">
    <div class="stepper-wrapper">
      <div class="step-one-wrapper">
        <button
          :class="
            dispayBtnNo === 1 || dispayBtnNo === 2 || dispayBtnNo === 3|| dispayBtnNo === 4 || dispayBtnNo === 5
              ? 'active-btn'
              : null
          "
          class="step-btn-one"
          disabled
        >
          1
        </button>
      </div>
      <div class="step-connector-linear-line-wrapper">
        <div
          :class="dispayBtnNo === 2 || dispayBtnNo === 3 || dispayBtnNo === 4 || dispayBtnNo === 5 ? 'active-line' : null"
          class="step-connector-linear-line"
        ></div>
      </div>
      <div class="step-one-wrapper">
        <button
          :class="dispayBtnNo === 2 || dispayBtnNo === 3 || dispayBtnNo === 4 || dispayBtnNo === 5 ? 'active-btn' : null"
          class="step-btn-two"
          disabled
        >
          2
        </button>
      </div>
      <div class="step-connector-linear-line-wrapper">
        <div
          :class="dispayBtnNo === 3 || dispayBtnNo === 4 || dispayBtnNo === 5 ? 'active-line' : null"
          class="step-connector-linear-line"
        ></div>
      </div>
      <div class="step-one-wrapper">
        <button
          :class="dispayBtnNo === 3 || dispayBtnNo === 4 || dispayBtnNo === 5 ? 'active-btn' : null"
          class="step-btn-three"
          disabled
        >
          3
        </button>
      </div>
      <div class="step-connector-linear-line-wrapper">
        <div
          :class="dispayBtnNo === 4 || dispayBtnNo === 5 ? 'active-line' : null"
          class="step-connector-linear-line"
        ></div>
      </div>
      <div class="step-one-wrapper">
        <button
          :class="dispayBtnNo === 4 || dispayBtnNo === 5 ? 'active-btn' : null"
          class="step-btn-three"
          disabled
        >
          4
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stepper-whole-wrapper {
  width: fit-content;
  margin: 20px auto;
  //position: absolute;
  //bottom: -8px;
  left: 0;
  right: 0;
}
.stepper-wrapper {
  display: flex;
  align-items: center;
}

.step-connector-linear-line {
  width: 140px;
  height: 7px;
  background-color: grey;
  transition: all 0.5s linear;
}

.active-line {
  background-color: #34d399;
}

.step-btn-one,
.step-btn-two,
.step-btn-three {
  width: 35px;
  height: 35px;
  background-color: grey;
  border: none;
  color: #f9fafb;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50%;
  outline: none;
  transition: all 0.5s;
}

.active-btn {
  background-color: #34d399;
}

@media (max-width: 425px) {
  .step-btn-one,
  .step-btn-two,
  .step-btn-three {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .step-connector-linear-line {
    width: 100px;
  }
}
</style>
