import auth from "../middlewares/auth";
export const keywordRankingRoutes = [
  {
    path: '/keyword_ranking',
    component: () => import(/* webpackChunkName: "business_settings" */ '../views/keyword_ranking/Index.vue'),
    name: 'keyword_ranking',
    meta: {
      middleware: [
        auth
      ]
    }
  }
];