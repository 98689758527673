export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE,
      tinyMCEApiKey: process.env.VUE_APP_TINY_MCE_API_KEY
    }
  },
  methods: {
    imageLoadOnError(e) {
      e.target.src = "/images/no-image.png"
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getFormattedCampaignName(campaign) {
      if (campaign.google_campaign_name)
        return this.formatCampaignName(campaign.name);
      else
        return this.removeUnderscore(campaign.name);
    },
    formatNumber(number, decimal = 0) {
      if (isNaN(number)) {
        return number;
      }

      return number.toLocaleString('en-US', {minimumFractionDigits: decimal, maximumFractionDigits: decimal});
    },
    showInfluencerLicenseMessage() {
        this.$swal.fire({
          position: 'top-right',
          icon: 'warning',
          toast: true,
          title: "This is a collab license",
          showConfirmButton: false,
          timer: 10000
        })
    },
    removeUnderscore(str) {
      return str.replaceAll('_', ' ');
    },
    formatCampaignName(name) {
      let n = name.replaceAll("_Ampd_amazon_com_", "");

      let words = n.split("_");

      if (words.length > 1)
        words.pop();

      return words.join(' ');
    },
    blockUI(id) {
        window.$('#' + id).block({
          message: '<i class="flaticon-spinner-1 spin"></i>',
          overlayCSS: {
            backgroundColor: '#000',
            opacity: 0.6,
            cursor: 'wait'
          },
          css: {
            border: 0,
            color: '#fff',
            padding: 0,
            backgroundColor: 'transparent'
          }
        });
    },
    unblockUI(id) {
      window.$('#' + id).unblock();
    },
    checkIfDuplicateExists(arr) {
      return new Set(arr).size !== arr.length
    },
    showSuccessMsg(msg) {
      this.$swal.fire({
        position: 'top-right',
        icon: 'success',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 10000
      })
    },
    showFailMsg(msg) {
      this.$swal.fire({
        position: 'top-right',
        icon: 'error',
        toast: true,
        title: msg,
        showConfirmButton: false,
        timer: 10000
      })
    },
    pageChanged(page) {
      this.queryParams.page = page;
      this.loadData();
    },
    sorting({column, order}) {
      this.queryParams.sort = column;
      this.queryParams.sort_order = order;
      this.queryParams.page = 1;
      this.loadData();
    },
    search({q, columns}) {
      this.queryParams.page = 1;
      this.queryParams.search = q;
      this.queryParams.search_columns = columns;
      this.loadData();
    },
    getFacebookCampaignStatus(statusCode) {
      let status = '';
      let color = '';

      if (statusCode === "PAUSED") {
        status = "PAUSED"
        color = "warning"
      } else if (statusCode === "ACTIVE") {
        status = "ACTIVE"
        color = "success"
      } else {
        status = "UNSPECIFIED"
        color = "secondary"
      }

      return {
        status,
        color
      }
    },
    getGoogleCampaignStatus(statusCode) {
      let status = '';
      let color = '';

      if (statusCode === 1) {
        status = "UNKNOWN"
        color = "info"
      } else if (statusCode === 2) {
        status = "ENABLED"
        color = "success"
      } else if (statusCode === 3) {
        status = "PAUSED"
        color = "warning"
      } else if (statusCode === 4) {
        status = "REMOVED"
        color = "danger"
      } else {
        status = "UNSPECIFIED"
        color = "secondary"
      }

      return {
        status,
        color
      }
    },
    getTwitterCampaignStatus(statusCode) {
      let status = '';
      let color = '';

      if (statusCode === 'PAUSED') {
        status = "PAUSED"
        color = "warning"
      } else if (statusCode === 'ACTIVE') {
        status = "ACTIVE"
        color = "success"
      } else if (statusCode === 'DRAFT') {
        status = "DRAFT"
        color = "warning"
      }

      return {
        status,
        color
      }
    },
  },
}